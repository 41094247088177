.home
    .oportunities .content
        display: flex
        flex-direction: column
        align-items: center
        gap: 30px
        min-height: 60vh
        padding: 50px 0

        h1
            text-align: center
            font-size: 40px
            span
                color: #609EE6

        .oportunities-searcher
            position: relative

            input
                width: 350px
                padding: 10px 40px
                border-radius: 25px
                border: 1px solid #105099
                font-size: 14px

            img
                position: absolute
                top: 50%
                transform: translateY(-50%)
                width: 20px

                &.search-icon
                    left: 15px

                &.filter-icon
                    right: 15px

        .job-types
            align-self: center
            justify-self: center 
            padding: 15px
            border-radius: 30px
            display: flex
            flex-direction: row
            box-shadow: 0px 1px 3px #a4a4a4
            gap: 15px

            .job-type
                padding: 6px 25px
                border-radius: 15px
                background: #105099
                color: white
                opacity: 0.4
                cursor: pointer
                transition: 0.2s

                &.selected
                    opacity: 1
                
                &:hover
                    opacity: 1

        .job-results
            width: 100%
            display: flex
            flex-direction: column
            gap: 25px

            .job
                width: 100%
                display: flex
                flex-direction: column
                border-radius: 30px
                overflow: hidden
                background: #105099
                box-shadow: 0px 1px 6px 1px #dbdbdb

                .top-row
                    width: 100%
                    display: flex
                    flex-direction: row
                    align-items: flex-start

                    .job-info
                        flex: 1
                        padding: 30px 50px
                        display: flex
                        flex-direction: row
                        align-items: center
                        justify-content: space-between
                    
                        *
                            color: white

                        div *
                            text-align: center

                    .sign-in
                        display: flex
                        align-items: center
                        justify-content: center
                        padding: 20px
                        background: white
                        align-self: stretch

                .bottom-row
                    background: white
                    border-radius: 30px 0 0 30px
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    align-items: center
                    padding: 15px

    .how-to-anounce
        width: 100%
        background: #EFF4FA

        .content
            padding: 50px 0

            .block
                border-radius: 30px
                box-shadow: 0px 1px 6px 1px #dbdbdb
                overflow: hidden

                .title
                    width: 100%
                    padding: 20px
                    background: #759DC8
                    h1
                        text-align: center
                        color: white

                .columns
                    width: 100%
                    display: flex
                    flex-direction: row
                    background: #105099
                    align-items: stretch
                    gap: 50px

                    .left, .right
                        flex: 1
                        display: flex
                        flex-direction: column
                        justify-content: space-between

                        *
                            color: white

                    .left
                        align-items: flex-end

                        .image-block
                            border-radius: 0px 30px 0px 0px

                    .right
                        .image-block
                            border-radius: 0px 0px 0px 30px

                    .steps
                        display: flex
                        flex-direction: column
                        gap: 25px
                        width: 80%
                        padding: 35px 0

                    .image-block
                        width: 100%
                        height: 350px
                        background: white
                        position: relative
                        overflow: hidden

    .about-us
        width: 100%
        background: #EFF4FA

        .content
            padding: 50px 0

            .block
                border-radius: 30px
                box-shadow: 0px 1px 6px 1px #dbdbdb
                overflow: hidden

                .title
                    width: 100%
                    padding: 20px
                    background: #105099
                    h1
                        text-align: center
                        color: white

                .about-text, .bottom-text
                    background: white
                    padding: 30px 10%

                .columns
                    width: 100%
                    display: flex
                    flex-direction: row
                    align-items: stretch
                    background: white
                    gap: 50px

                    .left
                        .text
                            align-self: flex-end

                    .left, .right
                        flex: 1
                        display: flex
                        flex-direction: column
                        gap: 20px

                        .text
                            width: 80%

                        div
                            display: flex
                            flex-direction: column
                            gap: 15px

                        ul, ol
                            display: flex
                            flex-direction: column
                            gap: 5px

                            li
                                position: relative
                                display: flex
                                flex-direction: row
                                &::before
                                    content: ''
                                    display: block
                                    width: 5px
                                    height: 5px
                                    border-radius: 50%
                                    background: black
                                    margin: 10px 10px 0 0
                                    flex-shrink: 0

                        h3
                            font-size: 32px

                        h4
                            font-size: 25px

                    .image-block
                        width: 100%
                        height: 350px
                        background: black
                        position: relative
                        overflow: hidden
                        border-radius: 30px 0 0 30px

    .success-stories
        width: 100%
        background: #EFF4FA

        .content
            padding: 50px 0

            .block
                border-radius: 30px
                box-shadow: 0px 1px 6px 1px #dbdbdb
                overflow: hidden

                .title
                    width: 100%
                    padding: 20px
                    background: #759DC8
                    h1
                        text-align: center
                        color: white

                div
                    padding: 30px 10%
                    background: #105099
                    * 
                        color: white

    .contact
        width: 100%
        background: #254569

        .content
            padding: 50px 0

            .block
                border-radius: 30px
                box-shadow: 0px 1px 6px 1px #191919
                overflow: hidden

                .title
                    width: 100%
                    padding: 20px
                    background: #759DC8
                    h1
                        text-align: center
                        color: white

                .block-content
                    background: #193351
                    * 
                        color: white

                .text
                    padding: 30px 10%

                .contact-email
                    padding: 10px 10% 30px
                    text-align: center

                .columns
                    width: 100%
                    display: flex
                    flex-direction: row
                    align-items: stretch
                    gap: 50px

                    .left
                        .form-container
                            width: 80%
                            align-self: flex-end

                            form
                                width: 100%
                                display: flex
                                flex-direction: column
                                align-items: flex-start
                                gap: 5px
                                margin-bottom: 15px

                            form, .input-container, input, textarea
                                width: 100%

                            input, textarea
                                padding: 10px 15px
                                border: 1px solid white
                                border-radius: 20px
                                background: none
                                color: white
                                margin-top: 5px

                            textarea
                                height: 200px

                    .left, .right
                        flex: 1
                        display: flex
                        flex-direction: column

                        .image-block
                            width: 100%
                            height: 100%
                            background: black
                            position: relative
                            overflow: hidden
                            border-radius: 30px 0 0 30px

    @media screen and (max-width: 900px)
        .content
            padding: 25px 0 !important

        .oportunities .content
            h1
                font-size: 26px

            .job-types
                flex-wrap: wrap
                gap: 10px

                .job-type
                    width: calc(50% - 5px)
                    font-size: 14px
                        
            .oportunities-searcher
                max-width: 350px
                width: 100%

                input
                    width: 100%
                    
            .job-results
                .job
                    background: white

                    .top-row
                        flex-direction: column

                        .job-info
                            width: 100%
                            flex-direction: column
                            background: #105099
                            padding: 15px

                        .sign-in
                            padding: 15px

                    .bottom-row
                        padding: 15px
                        border-radius: 0
                        gap: 5px

                    .sign-in
                        width: 100%
                        padding: 0 15px 15px

                        button
                            display: block
                            margin: auto

        .how-to-anounce
            .content
                .block
                    .title
                        padding: 10px
                        h1
                            font-size: 22px

                    .columns
                        flex-direction: column
                        gap: 0

                        .image-block
                            height: 250px

                        .column
                            width: 100%

                        .left
                            align-items: flex-start

                            .steps
                                width: 100%
                                padding: 20px 15px

                            .image-block 
                                border-radius: 0px

                        .right
                            flex-direction: column-reverse

                            .steps
                                width: 100%
                                padding: 20px 15px

        .about-us
            .content
                .block
                    .title
                        padding: 10px
                        h1
                            font-size: 22px

                    .about-text, .bottom-text
                        padding: 15px

                    .columns
                        flex-direction: column
                        gap: 15px

                        .text
                            padding: 0 15px
                            width: 100% !important

                            h3
                                font-size: 20px

                            h4
                                font-size: 18px

                        .image-block
                            border-radius: 0
                            height: 250px

        .success-stories
            .content
                .block
                    .title
                        padding: 10px
                        h1
                            font-size: 22px

                    div
                        padding: 15px

                        h2
                            font-size: 20px
                            margin-bottom: 5px
            

        .contact
            .content
                .block
                    .title
                        padding: 10px
                        h1
                            font-size: 22px

                    .text
                        padding: 15px 15px 0

                    .columns
                        flex-direction: column
                        margin-top: 15px
                        gap: 15px

                        .form-container
                            padding: 0 15px
                            width: 100% !important

                        .right
                            .image-block
                                border-radius: 0
                                height: 250px