.oportunities-searcher
    position: relative

    input
        width: 320px
        padding: 10px 40px
        border-radius: 25px
        border: 1px solid #105099
        font-size: 14px

    img
        position: absolute
        top: 50%
        transform: translateY(-50%)
        width: 20px

        &.search-icon
            left: 15px

        &.filter-icon
            right: 15px