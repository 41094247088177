.candidate-profile
    background: #F1F8FF
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 0

    .content
        align-items: center
        justify-content: center

    .container
        width: 85%
        position: relative

        .side-menu
            position: absolute
            right: 100%
            top: 0
            border-radius: 15px
            background: white
            box-shadow: 1px 2px 5px 1px #d0d0d0
            
            ul
                display: flex
                flex-direction: column

                li
                    padding: 15px
                    cursor: pointer

                    img
                        width: 35px

    .block
        width: 100%
        background: #105099
        border-radius: 20px
        display: flex
        align-items: center
        flex-direction: column
        gap: 15px
        min-height: 55vh
        padding: 35px
        position: relative

        *
            color: white

        h3
            font-size: 30px
            font-weight: 600

    .submit-btn
        margin-top: auto

    @media screen and (max-width: 1000px)
        .container
            .side-menu
                position: relative
                right: inherit
                margin: 0 auto 20px
                ul
                    flex-direction: row
                    align-items: center
                    justify-content: center

                    li
                        padding: 10px
                        img
                            width: inherit
                            height: 30px