.job-offer
    background: #105099
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 0

    .content
        align-items: center
        justify-content: center

    .block
        width: 80vw
        background: white
        border-radius: 20px
        overflow: hidden
        display: flex
        align-items: center
        flex-direction: column
        min-height: 55vh
        padding: 35px

        h3
            font-size: 30px
            font-weight: 600

        .columns
            width: 100%
            display: flex
            flex-direction: row
            align-items: stretch
            gap: 20px

        input, textarea
            background: #e9e9e9

    @media screen and (max-width: 1000px)
        .block
            width: 85%

            h3
                font-size: 26px

            .columns
                width: 100%
                flex-direction: column

                .column
                    width: 100%

        .slider
            .slide
                width: 40px
                height: 40px

            span
                font-size: 12px

            .slide-name
                text-align: center
                width: 50px !important

            .spacer
                width: 40px
