.job-offer .slider
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin: 20px 0 100px

    .slide
        width: 60px
        height: 60px
        border-radius: 50%
        border: 1.5px solid none
        background: #e9e9e9
        display: flex
        align-items: center
        justify-content: center
        position: relative

        &.checked
            background: #105099
            border: 1px solid #105099

            .number
                color: white !important

            .slide-name
                color: #105099

        &.current
            background: white
            border: 1px solid #105099

            .number
                color: #105099

            .slide-name
                font-weight: bold
                color: #105099

        span
            color: #6b6b6b

        .number
            font-weight: bold

        .slide-name
            position: absolute
            width: 100px
            top: 110%
            left: 50%
            transform: translateX(-50%)
            text-align: center

    .spacer
        width: 70px
        height: 4px
        background: #999999
        position: relative
        z-index: 0

        &::after
            content: ''
            height: 100%
            width: 0
            position: absolute
            background: #105099
            transition: 0.5s
            z-index: 1

        &.checked::after
            width: 100%