.company-sign-up-form
    width: 100%
    background: white
    border-radius: 20px
    overflow: hidden
    display: flex
    align-items: center
    flex-direction: column
    gap: 28px
    min-height: 55vh
    padding: 30px

    h3
        font-size: 26px
        font-weight: 600
        color: #105099 !important

    h4
        font-size: 18px

    .form, .form-container, .resume
        width: 100%
        display: flex
        align-items: center
        flex-direction: column

    .form-container
        width: 70%
        border-radius: 20px
        border: 1px solid #cacaca

        .text
            width: 100%
            padding: 18px
            // text-align: center
            border-bottom: 1px solid #cacaca

        .submit-block
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            padding: 18px

            div
                width: 50%

        .submit
            width: calc(  50% - 40px )
            padding: 12px
            margin: 18px
            align-self: flex-end

            &.disabled
                background: #8A8A8A

    .address-part p
        display: inline-block

    .columns
        width: 100%
        display: flex
        flex-direction: row
        align-items: flex-start
        border-bottom: 1px solid #cacaca
        align-items: stretch

        .column
            width: 50%
            display: flex
            flex-direction: column
            padding: 18px
            gap: 10px

            &:first-of-type
                border-right: 1px solid #cacaca

    input, textarea
        background: #e9e9e9

    .slider
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        margin-bottom: 45px

        .slide
            width: 55px
            height: 55px
            border-radius: 50%
            border: 1.5px solid none
            background: #e9e9e9
            display: flex
            align-items: center
            justify-content: center
            position: relative

            &.checked
                background: #105099
                border: 1px solid #105099

                .number
                    color: white

                .slide-name
                    color: #105099

            &.current
                background: white
                border: 1px solid #105099

                .number
                    color: #105099

                .slide-name
                    font-weight: bold
                    color: #105099

            span
               color: #6b6b6b

            .number
               font-weight: bold

            .slide-name
                position: absolute
                width: 100px
                top: 110%
                left: 50%
                transform: translateX(-50%)
                text-align: center
                line-height: 1.2

        .spacer
            width: 70px
            height: 4px
            background: #999999
            position: relative
            z-index: 0

            &::after
                content: ''
                height: 100%
                width: 0
                position: absolute
                background: #105099
                transition: 0.5s
                z-index: 1

            &.checked::after
                width: 100%

    @media screen and (max-width: 1000px)
        padding: 20px

        h3
            font-size: 24px

        h4
            font-size: 16px
            line-height: 1.1
            margin-bottom: 5px

        .slider
            margin-bottom: 32px
            .slide
                width: 45px
                height: 45px

            .slide-name
                width: 65px !important
                text-align: center

            span
                font-size: 11px

            .spacer
                width: 30px

        .form-container, .columns, .column
            border: none !important

        .form-container
            width: 100% !important

            .columns
                width: 100%
                flex-direction: column
                gap: 10px
                padding: 15px 0

                .column
                    width: 100%
                    padding: 0

            .submit
                align-self: center !important

            .text
                padding: 0 0 15px !important

            .submit-block
                border-top: 1px solid #cacaca
                flex-direction: column !important
                padding: 15px 0 0 0
                gap: 10px

                div
                    width: 100%