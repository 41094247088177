footer
    width: 100%
    padding: 30px
    background: #105099

    &.reserve-bg
        background: white
        .content *
            color: #105099

    .content
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        gap: 80px

        *
            color: white

        .column
            display: flex
            flex-direction: column

        .column:nth-of-type(1)
            align-items: center
            gap: 10px

            p
                text-align: center

        .column:nth-of-type(3)
            gap: 10px

        .social-medias
            display: flex
            flex-direction: row
            gap: 10px

            img
                width: 30px

    @media screen and (max-width: 1000px)
        .content
            flex-direction: column
            gap: 15px

            .column
                width: 200px
                align-items: center

            .column:nth-of-type(3)
                gap: 5px
