.modal-bg
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
    z-index: 100000000
    background: rgba(0, 0, 0, 0.5)
    display: flex
    align-items: center
    justify-content: center

    &.opened
        .modal
            scale: 1

    .modal
        width: 90%
        max-width: 500px
        padding: 20px
        border-radius: 15px
        background: white
        display: flex
        align-items: center
        flex-direction: column
        transition: 0.2s
        scale: 0

        .close-icon
            align-self: flex-end
            width: 24px
            cursor: pointer


