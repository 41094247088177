.reset-password
    background: #F1F8FF
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 0

    .content
        align-items: center
        justify-content: center

        .block
            width: 85%
            border-radius: 20px
            display: flex
            flex-direction: row
            align-items: stretch
            min-height: 55vh
            overflow: hidden

            @media screen and (max-width: 1000px)
                .block-content
                    width: 100% !important

                    h1
                        font-size: 25px !important

                    .form
                        width: 100% !important

            .img-container
                width: 50%
                overflow: hidden
                background: white
                display: flex
                align-items: center
                justify-content: center

                img
                    width: 100%

            .block-content
                width: 50%
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                background: #105099
                padding: 25px
                gap: 30px

                *
                    color: white

                &.not-sended
                    h1
                        color: white
                        margin-bottom: 8px
                        font-weight: 600
                        text-align: center

                    h2
                        color: white
                        font-weight: 600
                        text-align: center

                    .text
                        display: flex
                        flex-direction: column
                        align-items: center

                    .form
                        width: 75%
                        display: flex
                        flex-direction: column
                        gap: 15px

                        .extra-questions
                            width: 100%
                            display: flex
                            flex-direction: row
                            justify-content: space-between
                            align-items: center

                            .remember-me
                                display: flex
                                flex-direction: row
                                align-items: center
                                gap: 5px
                                cursor: pointer


                    .bottom-text
                        max-width: 320px
                        text-align: center
                        font-size: 14px

                &.sended
                    .text
                        display: flex
                        flex-direction: column
                        align-items: center
                        gap: 10px

                    .check
                        width: 100px

                    h3
                        color: white
                        font-weight: 600
                        text-align: center

                    .bottom-text
                        text-align: center
                    
