.select-container
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    gap: 5px
    position: relative
    z-index: 1
    cursor: pointer

    &.opened
        .arrow
            transform: rotatE(-180deg) translateY(50%)

    &.readonly
        input
            opacity: 0.6

    .select-input-container
        position: relative
        cursor: pointer

    input
        width: 100%
        color: black !important
        padding: 10px 15px
        border-radius: 20px
        font-size: 14px
        cursor: pointer

    .arrow
        width: 15px !important
        position: absolute
        right: 10px
        top: 50%
        transform: translateY(-50%)
        transition: 0.4s
        z-index: 10

    .options
        position: absolute
        top: 100%
        width: 100%
        max-height: 335px
        overflow: auto
        z-index: 1000000
        background: white

        ul
            width: 100%

        li
            width: 100%
            padding: 10px
            color: black
            transition: 0.2s
            display: flex
            flex-direction: row
            align-items: center
            gap: 10px

            input
                width: fit-content

            &:hover
                background: #c7c7c7