.resume
    *
        color: #105099

    .form-container
        width: 90%

        .column
            width: inherit
            flex: 1

            img
                width: 100%

            .data-block, .text-block
                width: 100%

            .data-block
                margin-bottom: 20px

        h4
            margin-bottom: 15px

        .submit-block
            flex-direction: column
            gap: 20px
            
            div
                width: 100%