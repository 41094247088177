.repprove-modal
    .modal-header
        width: 100%
        display: flex
        flex-direction: row
        align-items: flex-start
        gap: 10px
        margin-bottom: 15px

        h4
            font-weight: normal

    form
        width: 100%
        margin-bottom: 15px
    
        input
            background: #cccccc

    button
        display: block
        margin: auto