.personal-form
    width: 100%
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    gap: 10px

    .columns
        width: 100%
        display: flex
        flex-direction: row
        border-bottom: 1px dotted white
        padding: 5px 0

        &:last-of-type
            border-bottom: none

    .column
        width: 50%
        display: flex
        flex-direction: column
        gap: 10px
        padding: 10px


    @media screen and (max-width: 1000px)
        h1
            font-size: 26px

        .columns
            flex-direction: column
            padding: 15px 0
            gap: 10px

            .column
                width: 100%
                padding: 0px