.saved-offers
    width: 100%
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    gap: 10px

    .saved-offers-list
        width: 100%
        display: flex
        flex-direction: row
        flex-wrap: wrap
        gap: 10px

        .saved-offer
            padding: 15px
            border-radius: 15px
            background: white
            display: flex
            flex-direction: column
            align-items: flex-start
            gap: 5px

            *
                color: black

    @media screen and (max-width: 1000px)
        .saved-offer
            width: calc( 50% - 5px )

    @media screen and (max-width: 650px)
        .saved-offer
            width: 100%
