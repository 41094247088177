.job-offer .basic-info
    max-width: 960px
    width: 100%
    display: flex
    align-items: center
    flex-direction: column
    gap: 30px

    .descripiton-column, .input-container, textarea
        flex: 1

    .column
        width: 50%
        display: flex
        flex-direction: column
        gap: 10px

    .location-section
        width: 100%
        display: flex
        align-items: flex-start
        flex-direction: column
        gap: 5px

        .location-title
            display: flex
            flex-direction: row
            align-items: center
            gap: 10px

        b
            color: #105099

    .pcd-check
        display: flex
        flex-direction: row
        align-items: center
        gap: 10px