.modal-content
    display: flex
    flex-direction: column
    align-items: center

    .warning-icon
        width: 50px

    h1, h2, h3, h4, p, a
        color: black !important
        text-align: center

    h3
        font-size: 22px !important
        margin: 10px 0

    .btns
        width: 100%
        display: flex
        flex-direction: row
        justify-content: center !important
        gap: 20px
        margin-top: 20px

        button
            display: flex
            flex-direction: row
            align-items: center
            justify-content: center
            background: white
            gap: 10px
            box-shadow: 1px 2px 8px 2px #cbcbcb
            &:hover
                background: #dcdcdc

            img
                width: 20px

        .cancel
            span
                color: #105099

        .confirm
            span
                color: #FF3E4E

