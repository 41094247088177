.sign-up
    background: #F1F8FF
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 30px 0

    &.company
        background: #105099

        h1
            color: white !important

    .content
        align-items: center
        justify-content: center

        h1
            font-size: 26px
            color: #105099
            margin-bottom: 15px
            font-weight: 600

    .usertype-selector
        width: 85%
        background: white
        border-radius: 20px
        overflow: hidden
        display: flex
        flex-direction: row
        align-items: stretch
        min-height: 55vh

        .usertype
            flex: 1
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            cursor: pointer
            transition: 0.2s
            gap: 20px

            &.candidate
                background: #105099
                &:hover
                    background: #0a3567

                h3
                    color: white

            &.company
                background: white
                &:hover
                    background: #cacaca

                h3
                    color: #105099

            h3
                font-size: 30px
                font-weight: 600

            img
                width: 300px

    @media screen and (max-width: 800px)
        .content
            h1
                font-size: 28px

        .usertype-selector
            flex-direction: column

            .usertype
                padding: 25px
                gap: 15px

                h3
                    font-size: 25px

                img
                    width: 150px
