.checkbox-container
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    gap: 15px

    .options
        width: 100%
        display: flex
        flex-direction: column
        gap: 15px
        
        .option
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            cursor: pointer
            gap: 5px

            *
                cursor: pointer