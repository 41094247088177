.table
    .paginator
        display: flex
        flex-direction: row
        align-items: center
        gap: 10px
        width: fit-content
        margin: 10px auto 0

        span
            padding: 5px