.job-offer .requirements
    max-width: 960px
    width: 100%
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: 30px

    .column
        width: 50%
        display: flex
        flex-direction: column
        gap: 10px

    .requirement-section
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 10px

    .toggle-course-form, .toggle-hability-form
        display: flex
        flex-direction: row
        align-items: center
        gap: 10px
        border: 1px solid #105099

    .course-form, .hability-form
        width: 100%

        .columns
            margin-top: 15px

        button
            min-width: 0
            flex: 1

    button.secondary
        border: 1px solid #105099    

    button.primary
        align-self: center