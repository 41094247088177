.interview-modal
    .modal-header
        width: 100%
        display: flex
        flex-direction: row
        align-items: flex-start
        gap: 10px
        margin-bottom: 15px

        h4
            font-weight: normal

    form
        width: 100%
        margin-bottom: 15px

    .contact-selector
        width: 100%
        margin-bottom: 5px
        
        label
            display: block
            margin-bottom: 5px
        
        .contact
            width: 100%
            padding: 10px 15px
            background: #cccccc
            border-radius: 30px
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            cursor: pointer
            transition: 0.2s

            &:first-of-type
                margin-bottom: 10px

            &.selected
                background: #7a7a7a
                color: white

            img
                height: 20px

    .react-datepicker-wrapper
        input
            background: #cccccc

    button
        display: block
        margin: auto