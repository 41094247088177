.company-data
    .row
        display: flex
        flex-direction: row
        align-items: center
        gap: 15px

        .flex-1
            flex: 1

        &.location
            gap: 5px

    .block
        display: flex
        flex-direction: column
        gap: 15px

    .street-number-input-container
        width: 140px

    .checkbox-container 
        width: fit-content

    img
        width: 15px

    @media screen and (max-width: 1000px)
        .address-row
            flex-direction: column !important

            div
                width: 100%