.job-offer .benefits
    max-width: 960px
    width: 100%
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: 30px

    .salary-section
        width: 100%
        .wage-inputs
            width: 100%
            display: flex
            flex-direction: row
            align-items: flex-start
            gap: 15px
            margin-bottom: 5px

            .wage-input-container
                flex: 1

    .benefits-section
        display: flex
        align-items: flex-start
        flex-direction: column
        gap: 20px

        .toggle-benefit-form
            display: flex
            flex-direction: row
            align-items: center
            gap: 10px
            border: 1px solid #105099

        .benefit-form    
            .columns
                margin-top: 15px

            button.secondary
                border: 1px solid #105099    

    button.primary
        align-self: center