.candidate.company-page
    background: #105099
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 0

    .content
        align-items: center
        justify-content: center

    .block
        width: 80vw
        background: white
        border-radius: 20px
        overflow: hidden
        display: flex
        align-items: center
        flex-direction: column
        gap: 15px
        min-height: 55vh
        padding: 35px

        // h1, h2, h3, h4, p, a, span
        //     color: #105099 !important

        h3
            font-size: 22px
            font-weight: 600

        .block-header
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: flex-start

            .title
                display: flex
                flex-direction: row
                align-items: center
                gap: 15px

            .actions
                display: flex
                flex-direction: row
                gap: 15px

                button
                    display: flex
                    flex-direction: row
                    align-items: center
                    justify-content: center
                    gap: 15px
                    background: white
                    width: max-content !important
                    min-width: 150px

                    span
                        color: white

                    &.not-apt-btn
                        background: #FF3E4E
                        &:hover
                            background: #a8343e

                    &.interview-btn
                        background: #105099
                        &:hover
                            background: #0f3b6d


        .info
            width: 100%
            margin-top: 15px

            .columns
                width: 100%
                display: flex
                flex-direction: row
                align-items: flex-start
                gap: 15px
                margin-bottom: 15px

                .column
                    display: flex
                    flex-direction: column
                    gap: 15px
                    flex: 1

                    div
                        width: 100%
                        display: flex
                        flex-direction: column

                    .main
                        align-items: center
                        gap: 10px

                        *
                            text-align: center

                        .profile-picture-container
                            width: 150px
                            height: 150px
                            border-radius: 50%
                            overflow: hidden
                            align-items: center
                            justify-content: center

                            img
                                width: 100%
                                height: 100%
                                object-fit: contain

                    .experiences, .courses
                        padding: 15px 0

                        h3
                            padding: 0 15px 5px

                        .list
                            width: 100%
                            
                            div
                                width: 100%
                                padding: 15px
                                border-bottom: 1px solid #616161

                                &:last-of-type
                                    border: none
                                
                                b
                                    margin-bottom: 5px

                                .status
                                    width: max-content
                                    padding: 5px 10px
                                    margin-top: 5px
                                    border-radius: 30px
                                    border: 1px solid #646464
                                    font-weight: bold
                                    color: #646464
                                    
                                    &.finished
                                        border: 1px solid #105099
                                        color: #105099

                    .knowledges, .abilities
                        padding: 15px

                        h3
                            margin-bottom: 5px

                        .list
                            width: 100%
                            gap: 15px

                            span
                                width: max-content
                                padding: 8px 15px
                                margin-top: 5px
                                border-radius: 30px
                                border: 1px solid #105099
                                font-size: 18px

            .basic-info
                .basic-info-intro
                    flex-direction: row !important
                    align-items: center
                    gap: 20px

                    h3
                        width: 300px !important

                .info-line
                    max-width: 380px
                    word-wrap: break-word
                    flex-direction: row !important
                    align-items: center
                    gap: 15px

    @media screen and (max-width: 1200px)
        .block
            h3
                font-size: 20px
                font-weight: 600
            
            .block-header
                flex-direction: column !important
                gap: 15px

                .actions
                    width: 100%

                    button
                        flex: 1
                        width: inherit !important
                        min-width: inherit !important

                        span
                            font-size: 14px

            .info
                .columns
                    flex-direction: column !important

                    .column
                        width: 100%

                        .experiences, .courses
                            padding: 10px 0

                            h3
                                padding: 0 0 10px

                            .list
                                div
                                    padding: 10px 10px

                        .knowledges, .abilities
                            padding: 10px 0

                            h3
                                padding: 0 0 10px
                                margin: 0

                            .list
                                span
                                    margin-left: 10px
                                    font-size: 16px

                .basic-info .basic-info-intro
                        flex-direction: column !important
                        align-items: flex-start