.company-user-profile
    background: #105099
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 0

    .content
        align-items: center
        justify-content: center

    .block
        width: 80vw
        background: white
        border-radius: 20px
        overflow: hidden
        display: flex
        align-items: center
        flex-direction: column
        gap: 15px
        min-height: 55vh
        padding: 35px

        h1, h2, h3, h4, p, a, span
            color: #105099 !important

        h3
            font-size: 30px
            font-weight: 600

        .columns
            width: 100%
            display: flex
            flex-direction: row
            align-items: flex-start
            gap: 10px

            .column
                flex: 1
                display: flex
                flex-direction: column
                gap: 10px

                input
                    background: #e9e9e9

    @media screen and (max-width: 1000px)
        .block
            h3
                font-size: 26px !important

            .columns
                flex-direction: column
                padding: 15px 0
                gap: 10px

                .column
                    width: 100%
                    padding: 0px

