.benefits-list
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    gap: 15px

    .options
        width: 100%
        display: flex
        flex-direction: column
        gap: 15px
        
        .option
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            cursor: pointer
            gap: 5px

            .input-container
                width: max-content
                cursor: pointer
                display: flex
                flex-direction: row
                align-items: center
                gap: 10px

                input
                    width: max-content

            .extra-inputs
                display: flex
                flex-direction: row
                align-items: center
                gap: 10px