.company-page
    background: #105099
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 0

    .content
        align-items: center
        justify-content: center

    .block
        width: 80vw
        background: white
        border-radius: 20px
        overflow: hidden
        display: flex
        align-items: center
        flex-direction: column
        gap: 15px
        min-height: 55vh
        padding: 35px

        // h1, h2, h3, h4, p, a, span
        //     color: #105099 !important

        h3
            font-size: 30px
            font-weight: 600

        .block-header
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: flex-start

            .title
                display: flex
                flex-direction: row
                align-items: center
                gap: 15px

            .add-btn
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 15px
                position: relative

                img
                    position: absolute
                    left: 15px

    .general-vision
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 15px
        border-radius: 15px
        box-shadow: 0px 1px 4px #919191

        .data
            h3
                font-size: 20px
                margin-bottom: 5px

            h4
                font-weight: normal

        .graph-container
            display: flex
            flex-direction: column
            align-items: center
            gap: 10px

    .oportunities-searcher
        position: relative

        input
            width: 350px
            padding: 10px 40px
            border-radius: 25px
            border: 1px solid #105099
            font-size: 14px

        img
            position: absolute
            top: 50%
            transform: translateY(-50%)
            width: 20px !important

            &.search-icon
                left: 15px

            &.filter-icon
                right: 15px

    .offers
        width: 100%

    .job-results
        width: 100%

        table
            width: 100%
            border-collapse: collapse

        .people-icon, .edit-icon
            cursor: pointer

        thead
            th
                padding: 10px 15px
                text-align: left

        tbody
            tr
                border: 1px solid #E6E6E6
            td
                padding: 10px 15px
                text-align: left

                .status
                    padding: 6px 12px
                    border-radius: 30px
                    font-size: 14px
                    font-weight: 600

                    &.open
                        background: #9FD67B

                    &.finished
                        background: #105099
                        color: white

                    &.canceled
                        background: #8A8A8A
                        color: white

    @media screen and (max-width: 1000px)
        .block
            width: 85% !important
            padding: 20px !important

            .title
                h3
                    font-size: 20px

            .add-btn
                width: 165px
                min-width: inherit

            .oportunities-searcher
                width: 100%
                max-width: 350px

                input
                    width: 100%

        .job-results
            overflow: auto
            thead
                th
                    padding: 10px !important
                    &.name
                        min-width: 160px !important

                    &.basic-info
                        min-width: 150px

                    &.pcd
                        min-width: 50px

                    &.see-candidates
                        min-width: 140px

                    &.actions
                        min-width: 120px
            
            tbody
                td
                    padding: 10px !important


