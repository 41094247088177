.candidate-sign-up-form
    width: 100%
    background: #105099
    border-radius: 20px
    display: flex
    align-items: center
    flex-direction: column
    gap: 15px
    min-height: 55vh
    padding: 30px

    *
        color: white

    h3
        font-size: 26px
        font-weight: 600
        color: white !important

    .columns
        display: flex
        flex-direction: row
        align-items: flex-start
        gap: 10px

        .column
            width: 33.3%
            display: flex
            flex-direction: column
            gap: 10px

            img
                width: 100%

    .terms-and-conditions-container
        p
            font-size: 16px
            margin-bottom: 5px

    @media screen and (max-width: 1000px)
        padding: 20px

        h3
            font-size: 24px

        .columns
            width: 100%
            flex-direction: column

            .column
                width: 100%

                img
                    width: 200px
                    align-self: center