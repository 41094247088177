.company-page
    background: #105099
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 0

    .content
        align-items: center
        justify-content: center

    .block
        width: 80vw
        background: white
        border-radius: 20px
        overflow: hidden
        display: flex
        align-items: center
        flex-direction: column
        gap: 15px
        min-height: 55vh
        padding: 35px

        // h1, h2, h3, h4, p, a, span
        //     color: #105099 !important

        h3
            font-size: 30px
            font-weight: 600

        .block-header
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: flex-start

            .title
                display: flex
                flex-direction: row
                align-items: center
                gap: 15px

                a
                    font-weight: normal

            .add-btn
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 15px
                position: relative

                img
                    position: absolute
                    left: 15px

    .general-vision
        width: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 15px
        border-radius: 15px
        box-shadow: 0px 1px 4px #919191

        .data
            h3
                font-size: 20px
                margin-bottom: 5px

            h4
                font-weight: normal

        .graph-container
            display: flex
            flex-direction: column
            align-items: center
            gap: 10px

    .searcher
        position: relative

        input
            width: 350px
            padding: 10px 40px
            border-radius: 25px
            border: 1px solid #105099
            font-size: 14px

        img
            position: absolute
            top: 50%
            transform: translateY(-50%)
            width: 20px !important

            &.search-icon
                left: 15px

            &.filter-icon
                right: 15px

    .candidates
        width: 100%

    .results
        width: 100%
        overflow: auto

        table
            width: 100%
            border-collapse: collapse

        .people-icon, .edit-icon
            cursor: pointer

        thead
            th
                padding: 10px 15px
                text-align: left

                &.name
                    min-width: 200px

                &.basic-info
                    min-width: 150px

                &.pcd
                    min-width: 50px

                &.status
                    min-width: 120px

                &.actions
                    min-width: 500px

        tbody
            tr
                border: 1px solid #E6E6E6

                &.not-hired
                    opacity: 0.5
            td
                padding: 10px 15px
                text-align: left

            .actions
                width: max-content
                display: flex
                flex-direction: row
                align-items: center
                gap: 20px

                button
                    display: flex
                    flex-direction: row
                    align-items: center
                    gap: 10px
                    min-width: fit-content
                    box-shadow: 0px 2px 4px 0px #bdbdbd                    
                    span
                        font-size: 14px

                    &.hire
                        background: #9FD67B
                        &:hover
                            background: #69a044

                    &.remove
                        background: #CE3C48
                        &:hover
                            background: #af2c37

                        span
                            color: white

                .status
                    display: flex
                    flex-direction: row
                    align-items: center
                    gap: 10px
                    min-width: fit-content
                    box-shadow: 0px 2px 4px 0px #bdbdbd
                    padding: 10px 15px
                    border-radius: 30px
                    span
                        font-size: 14px
                        color: white
                        font-weight: bold

                    &.hired
                        background: #105099

                    &.not-hired
                        background: #A4A4A4

    @media screen and (max-width: 1000px)
        .block
            width: 85% !important
            padding: 20px !important

            .title
                h3
                    font-size: 20px

            .searcher
                width: 100%
                max-width: 350px

                input
                    width: 100%

        .results
            thead
                th
                    padding: 10px !important
                    &.name
                        min-width: 160px !important

                    &.basic-info
                        min-width: 150px

                    &.pcd
                        min-width: 50px

                    &.status
                        min-width: 120px

                    &.actions
                        min-width: 500px
            
            tbody
                td
                    padding: 10px !important
