.slider-container
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    gap: 20px

    .item-slider-container
        width: 100%
        height: 50vh
        overflow: hidden
        position: relative

        @media screen and (max-width: 1400px)
            width: 100%
            height: 80vh

        .slides
            display: flex
            flex-direction: row
            height: 100%

            .slide
                height: 100%
                flex-shrink: 0
                flex-grow: 0
                position: absolute
                overflow: hidden
                transition: 0.3s
                background: #1d1d1d

                .background
                    position: absolute
                    width: 100%
                    height: 100%
                    object-fit: cover,
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)

        .jump-btn
            position: absolute
            top: 50%
            transform: translateY(-50%)
            cursor: pointer
            z-index: 100
            min-width: 32px
            width: 32px
            height: 32px
            border-radius: 50%
            background: rgba(0, 0, 0, 0.5)
            display: flex
            align-items: center
            justify-content: center

            img
                width: 10px

            &.previous
                left: 20px

            &.next
                right: 20px

        .slide-dots
            position: absolute
            left: 50%
            transform: translateX(-50%)
            bottom: 50px
            display: flex
            flex-direction: row
            gap: 15px
            z-index: 100

            .dot
                width: 12px
                height: 12px
                background: rgba(255, 255, 255, 0.4)
                border-radius: 50%
                cursor: pointer

                &.selected
                    background: white