.job-offer .summary
    max-width: 960px
    width: 100%
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: 30px

    .section
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 10px

    .section-header
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-between

        .edit
            display: flex
            flex-direction: row
            align-items: center
            gap: 5px
            cursor: pointer

            span
                color: #105099
                font-weight: bold

    .salary
        color: #105099
        font-weight: bold

    .basic-info-section, .requirements-section
        .column
            flex: 1

    .location-title
        display: flex
        flex-direction: row
        align-items: center
        gap: 5px

    p
        line-height: 1
        margin-bottom: 5px

    .selections
        display: flex
        flex-direction: row
        align-items: center
        flex-wrap: wrap
        gap: 6px

        .selection
            padding: 6px 15px
            border-radius: 30px
            border: 1px solid #105099
            color: #3b3b3b
            font-size: 14px

    button.primary
        align-self: center