.candidate-sign-up-form-confirmation-success
    background: #F1F8FF
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 50px 0

    &.company
        background: #105099

    .content
        align-items: center
        justify-content: center

    .block
        width: 600px
        background: #105099
        border-radius: 20px
        overflow: hidden
        display: flex
        align-items: center
        flex-direction: column
        gap: 15px
        min-height: 55vh
        padding: 35px

        *
            color: white
            text-align: center

        h3
            font-size: 30px
            font-weight: 600
            color: white !important

        img
            width: 400px

    @media screen and (max-width: 700px)
        .block
            width: 85%

            h3
                font-size: 26px

            img
                width: 100%