header.mobile
    width: 100%
    position: fixed
    top: 0
    left: 0
    background: white
    height: 50px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    z-index: 1000

    .logo
        height: 35px

    .menu-btn
        width: 35px
        position: fixed
        top: 13px
        left: 15px
        z-index: 2000
        height: 25px

        span
            width: 100%
            height: 5px
            background: #105099
            border-radius: 5px
            display: block
            position: absolute
            transition: 0.2s

            &:nth-of-type(2)
                top: 10px

            &:nth-of-type(3)
                top: 20px

        &.opened
            span
                &:nth-of-type(1)
                    top: 10px
                    transform: rotate(45deg)

                &:nth-of-type(2)
                    display: none

                &:nth-of-type(3)
                    top: 10px            
                    transform: rotate(-45deg)

    .menu
        position: fixed
        top: 0
        bottom: 0
        width: 300px
        left: -300px
        transition: 0.3s
        z-index: 1500
        background: black
        background: white
        padding: 65px 15px
        box-shadow: 0px 0px 4px 0px #474747

        &.opened
            left: 0

        .user-data
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            gap: 8px
            border-radius: 25px
            cursor: pointer
            margin-bottom: 10px

            &.opened
                border-radius: 15px 15px 0 0

                .arrow
                    transform: rotate(-180deg)

            h4
                font-size: 14px
                font-weight: normal

            p
                font-size: 12px

            .profile-picture-container
                width: 30px
                height: 30px
                border-radius: 50%
                overflow: hidden
                display: flex
                align-items: center
                justify-content: center
                background: #444

                img
                    width: 100%
                    height: 100%
                    object-fit: cover

        .oportunities-searcher
            width: 100%
            margin-bottom: 10px

            input
                width: 100%

        ul
            flex-direction: column

            li
                padding: 5px 0