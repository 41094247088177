.experience-form
    width: 100%
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    gap: 10px

    .experiences
        width: 100%
        display: flex
        flex-direction: column
        gap: 15px

    .form
        width: 100%
        padding: 15px
        border-radius: 15px
        background: #196ac5

        .btns
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between

    .columns-group
        width: 100%
        display: flex
        flex-direction: column
        margin-bottom: 15px
        gap: 15px

        &:last-of-type
            border-bottom: none

    .columns
        width: 100%
        display: flex
        flex-direction: row
        gap: 15px

    .column
        width: 50%
        display: flex
        flex-direction: column
        gap: 15px

    @media screen and (max-width: 1000px)
        h1
            font-size: 26px
    
        .columns
            flex-direction: column
            gap: 10px

            .column
                width: 100%

        .btns
            gap: 10px

            button
                min-width: inherit
                flex: 1