.modal-content
    display: flex
    flex-direction: column
    align-items: center

    .icon
        width: 50px

    h1, h2, h3, h4, p, a
        color: black !important
        text-align: center

    h3
        font-size: 22px !important
        margin: 10px 0

