@font-face
    font-family: OpenSans
    src: url(../assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf)
    
*
    margin: 0
    padding: 0
    box-sizing: border-box
    font-family: OpenSans
    color: black

    &::-webkit-scrollbar
        display: block
        overflow-y: scroll
        overflow-x: scroll

    &::-webkit-scrollbar
        width: 12px

    &::-webkit-scrollbar-track
        background: #cecece

    &::-webkit-scrollbar-thumb
        background: #105099

    &::-webkit-scrollbar-thumb:hover
        background: #0a3567

@import ./bootstrap

.page
    width: 100%
    min-height: 100vh
    display: flex
    flex-direction: column

.content
    width: calc( 100% - 20px )
    max-width: 1350px
    margin: auto
    display: flex
    flex-direction: column
    justify-content: flex-start

.page-content
    flex: 1
    padding-top: 80px
    display: flex
    flex-direction: column
    width: 100%
    
    @media screen and (max-width: 1000px)
        padding-top: 50px

// h1, h2, h3, h4, p, a, span, li
//     color: #202020

p
    font-size: 14px

input, select, textarea
    border: none
    outline: none

button
    min-width: 200px
    padding: 10px 15px
    border-radius: 30px
    font-weight: bold
    font-size: 16px
    text-align: center
    transition: 0.2s

    &.primary
        background: #105099
        color: white !important
        span
            color: white !important

        &:hover
            background: #0a3567

    &.secondary
        background: white
        color: #105099 !important
        &:hover
            background: #92b8e3

    &.cancel
        background: white
        color: #eb5757 !important
        border: 1px solid #eb5757
        &:hover
            background: #d89393

.cut-text
  width: 150px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.react-datepicker-wrapper
    width: 100%